import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_15bedddc_j9xTpfLwQp from "/vercel/path0/.nuxt/templates.pwa.client.15bedddc.ts";
import nuxt_plugin_6wEQMY3tee from "/vercel/path0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_8SbxDRbG6Y from "/vercel/path0/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_client_OVoKJro5pc from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_tbFNToZNim from "/vercel/path0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_AUP22rrBAc from "/vercel/path0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import ahoy_client_HtNXopC4Jh from "/vercel/path0/plugins/ahoy.client.js";
import filters_znakZuvLqA from "/vercel/path0/plugins/filters.js";
import maska_r8BHBbSmHt from "/vercel/path0/plugins/maska.js";
import posthog_client_o9IEgU9LwG from "/vercel/path0/plugins/posthog.client.js";
import shoelace_wmJJ1mQWmC from "/vercel/path0/plugins/shoelace.ts";
import vue3_toastify_OGYNDsiW9E from "/vercel/path0/plugins/vue3-toastify.ts";
import vuedatepicker_CrkHxJMSd1 from "/vercel/path0/plugins/vuedatepicker.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  templates_pwa_client_15bedddc_j9xTpfLwQp,
  nuxt_plugin_6wEQMY3tee,
  plugin_8SbxDRbG6Y,
  plugin_client_OVoKJro5pc,
  plugin_tbFNToZNim,
  plugin_AUP22rrBAc,
  ahoy_client_HtNXopC4Jh,
  filters_znakZuvLqA,
  maska_r8BHBbSmHt,
  posthog_client_o9IEgU9LwG,
  shoelace_wmJJ1mQWmC,
  vue3_toastify_OGYNDsiW9E,
  vuedatepicker_CrkHxJMSd1
]