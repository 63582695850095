import { default as arquivoIaCPoOWTztMeta } from "/vercel/path0/pages/arquivo.vue?macro=true";
import { default as extrato2IT9N36iPtMeta } from "/vercel/path0/pages/carteira/extrato.vue?macro=true";
import { default as indexP8SdDIXJZcMeta } from "/vercel/path0/pages/carteira/index.vue?macro=true";
import { default as carteiraBJsJpw7M5dMeta } from "/vercel/path0/pages/carteira.vue?macro=true";
import { default as indextvhAZ7Kq3ZMeta } from "/vercel/path0/pages/central-de-ajuda/index.vue?macro=true";
import { default as cardv6yDC6gjDNMeta } from "/vercel/path0/pages/checkout/[orderId]/card.vue?macro=true";
import { default as pixgcOkpDyM3aMeta } from "/vercel/path0/pages/checkout/[orderId]/pix.vue?macro=true";
import { default as cidadesHUuarlmbBJMeta } from "/vercel/path0/pages/cidades.vue?macro=true";
import { default as como_45funcionaMCnCkGn0OYMeta } from "/vercel/path0/pages/como-funciona.vue?macro=true";
import { default as contaRNCjikNgMWMeta } from "/vercel/path0/pages/conta.vue?macro=true";
import { default as crie_45seu_45eventoU6FBsWx0yhMeta } from "/vercel/path0/pages/crie-seu-evento.vue?macro=true";
import { default as entrark4vXiRcpiKMeta } from "/vercel/path0/pages/entrar.vue?macro=true";
import { default as _91_91tag_93_93ggxRb0a0jnMeta } from "/vercel/path0/pages/eventos/[city]/[[tag]].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexCuWPBj39BKMeta } from "/vercel/path0/pages/ingressos/[id]/index.vue?macro=true";
import { default as transferirpUbwsrhAi1Meta } from "/vercel/path0/pages/ingressos/[id]/transferir.vue?macro=true";
import { default as trocar_45nomeIs3jHvMEAGMeta } from "/vercel/path0/pages/ingressos/[id]/trocar-nome.vue?macro=true";
import { default as indexNVa52wjbwoMeta } from "/vercel/path0/pages/ingressos/index.vue?macro=true";
import { default as passadosDCduC7sErLMeta } from "/vercel/path0/pages/ingressos/passados.vue?macro=true";
import { default as my_accountR2VGeWjWHNMeta } from "/vercel/path0/pages/my_account.vue?macro=true";
import { default as indexH5AaUUeSy6Meta } from "/vercel/path0/pages/onboarding/index.vue?macro=true";
import { default as cancelarxkE6LxYVMYMeta } from "/vercel/path0/pages/orders/[id]/cancelar.vue?macro=true";
import { default as indexMzbDRHMBrDMeta } from "/vercel/path0/pages/orders/[id]/index.vue?macro=true";
import { default as indexan9ZrZAr6jMeta } from "/vercel/path0/pages/orders/index.vue?macro=true";
import { default as editmoi9t0929fMeta } from "/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/edit.vue?macro=true";
import { default as index6fcOqsrXuFMeta } from "/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/index.vue?macro=true";
import { default as send7wDVBgKFIlMeta } from "/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/send.vue?macro=true";
import { default as indexljmFmtnJqIMeta } from "/vercel/path0/pages/p/[slug]/campaigns/index.vue?macro=true";
import { default as new9Bb5FdVE4MMeta } from "/vercel/path0/pages/p/[slug]/campaigns/new.vue?macro=true";
import { default as campaignsDfCQHEsYkPMeta } from "/vercel/path0/pages/p/[slug]/campaigns.vue?macro=true";
import { default as editXIVbAO1LNGMeta } from "/vercel/path0/pages/p/[slug]/edit.vue?macro=true";
import { default as editCxWmwn5ZkuMeta } from "/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/edit.vue?macro=true";
import { default as indexsL6R3wPZORMeta } from "/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/index.vue?macro=true";
import { default as indexDyHHDt0pBNMeta } from "/vercel/path0/pages/p/[slug]/guestlists/index.vue?macro=true";
import { default as new3LWjwTB37pMeta } from "/vercel/path0/pages/p/[slug]/guestlists/new.vue?macro=true";
import { default as guestlistsfTSphu4R1VMeta } from "/vercel/path0/pages/p/[slug]/guestlists.vue?macro=true";
import { default as indextg33DI1USAMeta } from "/vercel/path0/pages/p/[slug]/index.vue?macro=true";
import { default as _91guestlistId_93dnnBRI1DKFMeta } from "/vercel/path0/pages/p/[slug]/listas/[guestlistId].vue?macro=true";
import { default as galerial57XEToYTFMeta } from "/vercel/path0/pages/parceiros/[slug]/galeria.vue?macro=true";
import { default as indexkccEgoEs10Meta } from "/vercel/path0/pages/parceiros/[slug]/index.vue?macro=true";
import { default as ranking24INbcWy9LMeta } from "/vercel/path0/pages/parceiros/[slug]/ranking.vue?macro=true";
import { default as _91slug_93GzY2HDfJMOMeta } from "/vercel/path0/pages/parceiros/[slug].vue?macro=true";
import { default as indexQpXVhpZ6xmMeta } from "/vercel/path0/pages/parceiros/index.vue?macro=true";
import { default as indexZETROZKIcTMeta } from "/vercel/path0/pages/producer_wizard/index.vue?macro=true";
import { default as new3aF4rqkogXMeta } from "/vercel/path0/pages/producer_wizard/new.vue?macro=true";
import { default as _91slug_93OsOWxVXvk9Meta } from "/vercel/path0/pages/producer_wizard/success/[slug].vue?macro=true";
import { default as reducao_45de_45danostEDuAduzQUMeta } from "/vercel/path0/pages/reducao-de-danos.vue?macro=true";
import { default as resgatarTT0vypIzdFMeta } from "/vercel/path0/pages/resgatar.vue?macro=true";
import { default as indexRYqrGfppHXMeta } from "/vercel/path0/pages/u/[slug]/[productSlug]/index.vue?macro=true";
import { default as indexVeV4FOmvW8Meta } from "/vercel/path0/pages/u/[slug]/index.vue?macro=true";
export default [
  {
    name: "arquivo",
    path: "/arquivo",
    component: () => import("/vercel/path0/pages/arquivo.vue").then(m => m.default || m)
  },
  {
    name: carteiraBJsJpw7M5dMeta?.name,
    path: "/carteira",
    component: () => import("/vercel/path0/pages/carteira.vue").then(m => m.default || m),
    children: [
  {
    name: "carteira-extrato",
    path: "extrato",
    component: () => import("/vercel/path0/pages/carteira/extrato.vue").then(m => m.default || m)
  },
  {
    name: "carteira",
    path: "",
    component: () => import("/vercel/path0/pages/carteira/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "central-de-ajuda",
    path: "/central-de-ajuda",
    component: () => import("/vercel/path0/pages/central-de-ajuda/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-orderId-card",
    path: "/checkout/:orderId()/card",
    component: () => import("/vercel/path0/pages/checkout/[orderId]/card.vue").then(m => m.default || m)
  },
  {
    name: "checkout-orderId-pix",
    path: "/checkout/:orderId()/pix",
    component: () => import("/vercel/path0/pages/checkout/[orderId]/pix.vue").then(m => m.default || m)
  },
  {
    name: "cidades",
    path: "/cidades",
    component: () => import("/vercel/path0/pages/cidades.vue").then(m => m.default || m)
  },
  {
    name: "como-funciona",
    path: "/como-funciona",
    component: () => import("/vercel/path0/pages/como-funciona.vue").then(m => m.default || m)
  },
  {
    name: "conta",
    path: "/conta",
    component: () => import("/vercel/path0/pages/conta.vue").then(m => m.default || m)
  },
  {
    name: "crie-seu-evento",
    path: "/crie-seu-evento",
    component: () => import("/vercel/path0/pages/crie-seu-evento.vue").then(m => m.default || m)
  },
  {
    name: "entrar",
    path: "/entrar",
    component: () => import("/vercel/path0/pages/entrar.vue").then(m => m.default || m)
  },
  {
    name: "eventos-city-tag",
    path: "/eventos/:city()/:tag?",
    component: () => import("/vercel/path0/pages/eventos/[city]/[[tag]].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "ingressos-id",
    path: "/ingressos/:id()",
    component: () => import("/vercel/path0/pages/ingressos/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "ingressos-id-transferir",
    path: "/ingressos/:id()/transferir",
    component: () => import("/vercel/path0/pages/ingressos/[id]/transferir.vue").then(m => m.default || m)
  },
  {
    name: "ingressos-id-trocar-nome",
    path: "/ingressos/:id()/trocar-nome",
    component: () => import("/vercel/path0/pages/ingressos/[id]/trocar-nome.vue").then(m => m.default || m)
  },
  {
    name: "ingressos",
    path: "/ingressos",
    component: () => import("/vercel/path0/pages/ingressos/index.vue").then(m => m.default || m)
  },
  {
    name: "ingressos-passados",
    path: "/ingressos/passados",
    component: () => import("/vercel/path0/pages/ingressos/passados.vue").then(m => m.default || m)
  },
  {
    name: "my_account",
    path: "/my_account",
    component: () => import("/vercel/path0/pages/my_account.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    component: () => import("/vercel/path0/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-cancelar",
    path: "/orders/:id()/cancelar",
    component: () => import("/vercel/path0/pages/orders/[id]/cancelar.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/vercel/path0/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/vercel/path0/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: campaignsDfCQHEsYkPMeta?.name,
    path: "/p/:slug()/campaigns",
    meta: campaignsDfCQHEsYkPMeta || {},
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns.vue").then(m => m.default || m),
    children: [
  {
    name: "p-slug-campaigns-campaignId-edit",
    path: ":campaignId()/edit",
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "p-slug-campaigns-campaignId",
    path: ":campaignId()",
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/index.vue").then(m => m.default || m)
  },
  {
    name: "p-slug-campaigns-campaignId-send",
    path: ":campaignId()/send",
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/send.vue").then(m => m.default || m)
  },
  {
    name: "p-slug-campaigns",
    path: "",
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "p-slug-campaigns-new",
    path: "new",
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "p-slug-edit",
    path: "/p/:slug()/edit",
    meta: editXIVbAO1LNGMeta || {},
    component: () => import("/vercel/path0/pages/p/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: guestlistsfTSphu4R1VMeta?.name,
    path: "/p/:slug()/guestlists",
    meta: guestlistsfTSphu4R1VMeta || {},
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists.vue").then(m => m.default || m),
    children: [
  {
    name: "p-slug-guestlists-guestlistId-edit",
    path: ":guestlistId()/edit",
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "p-slug-guestlists-guestlistId",
    path: ":guestlistId()",
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/index.vue").then(m => m.default || m)
  },
  {
    name: "p-slug-guestlists",
    path: "",
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/index.vue").then(m => m.default || m)
  },
  {
    name: "p-slug-guestlists-new",
    path: "new",
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "p-slug",
    path: "/p/:slug()",
    component: () => import("/vercel/path0/pages/p/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "p-slug-listas-guestlistId",
    path: "/p/:slug()/listas/:guestlistId()",
    component: () => import("/vercel/path0/pages/p/[slug]/listas/[guestlistId].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GzY2HDfJMOMeta?.name,
    path: "/parceiros/:slug()",
    component: () => import("/vercel/path0/pages/parceiros/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "parceiros-slug-galeria",
    path: "galeria",
    component: () => import("/vercel/path0/pages/parceiros/[slug]/galeria.vue").then(m => m.default || m)
  },
  {
    name: "parceiros-slug",
    path: "",
    component: () => import("/vercel/path0/pages/parceiros/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "parceiros-slug-ranking",
    path: "ranking",
    component: () => import("/vercel/path0/pages/parceiros/[slug]/ranking.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "parceiros",
    path: "/parceiros",
    component: () => import("/vercel/path0/pages/parceiros/index.vue").then(m => m.default || m)
  },
  {
    name: "producer_wizard",
    path: "/producer_wizard",
    component: () => import("/vercel/path0/pages/producer_wizard/index.vue").then(m => m.default || m)
  },
  {
    name: "producer_wizard-new",
    path: "/producer_wizard/new",
    component: () => import("/vercel/path0/pages/producer_wizard/new.vue").then(m => m.default || m)
  },
  {
    name: "producer_wizard-success-slug",
    path: "/producer_wizard/success/:slug()",
    component: () => import("/vercel/path0/pages/producer_wizard/success/[slug].vue").then(m => m.default || m)
  },
  {
    name: "reducao-de-danos",
    path: "/reducao-de-danos",
    component: () => import("/vercel/path0/pages/reducao-de-danos.vue").then(m => m.default || m)
  },
  {
    name: "resgatar",
    path: "/resgatar",
    component: () => import("/vercel/path0/pages/resgatar.vue").then(m => m.default || m)
  },
  {
    name: "u-slug-productSlug",
    path: "/u/:slug()/:productSlug()",
    component: () => import("/vercel/path0/pages/u/[slug]/[productSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "u-slug",
    path: "/u/:slug()",
    component: () => import("/vercel/path0/pages/u/[slug]/index.vue").then(m => m.default || m)
  }
]