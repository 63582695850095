<template>
  <div>
    <VitePwaManifest />
    <NuxtLoadingIndicator :height="1" color="repeating-linear-gradient(to right,#ff64a0 0%, #fa64ff 50%,#fe62a1 100%)" />
    <!-- <div class="absolute top-0 left-0 w-full p-2 bg-slate-800 text-pink-500 text-center">
      <span class="text-lg">estamos em manutenção até as 14h</span>
    </div> -->
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <StagingAlert v-if="isStaging" />
  </div>
</template>

<script setup>
const isStaging = ref(false)
onMounted(() => {
  if (window.location.host.toString().includes('pixta.pro')) isStaging.value = true
})

useSchemaOrg([
  defineOrganization({
    name: 'pixta.me',
    aggregateRating: {
      ratingValue: '5',
      ratingCount: '4200'
    },
    foundingDate: '2021',
    foundingLocation: 'Curitiba, PR',
    keywords: 'ingressos, eventos, curitiba, pix, pixta, pixtame, cartao',
    logo: '/img/icon-no-bg.png',
    sameAs: [
      'https://twitter.com/pixta_me',
      'https://instagram.com/pixta.me',
      'https://www.youtube.com/@pixta.me_'
    ],
    url: 'https://pixta.me'
  })
])

useHead({
  titleTemplate: (title) => !title ? 'PIXTA.me' : `${title} | PIXTA.me`,
  description: 'Compre e venda ingressos para seus eventos, por pix e cartão, com agilidade e segurança', // max 155 / 200
  htmlAttrs: {
    lang: 'pt-BR'
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes' }
  ],
  bodyAttrs: {
    // class: 'dark'
  },
  link: [
    // { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
    // { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;900&display=swap' },
    // { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
    // { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: '' }

    { rel: "stylesheet", media: "(prefers-color-scheme:light)", href: "https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.9.0/cdn/themes/light.css" },
    { rel: "stylesheet", media: "(prefers-color-scheme:dark)", href: "https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.9.0/cdn/themes/dark.css", onload: "document.documentElement.classList.add('sl-theme-dark');" }
  ],
  script: [
    // { src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAixflCc-p8uJO-eGleMs4QPBpFcIptBGc&libraries=places' }
  ]
})
</script>
